import React, { useState } from "react";
import { Modal, Form, notification, Upload, Row, Col ,Button,Input,Space} from "antd";
import Joi from "joi";
import { PlusOutlined,MinusCircleOutlined } from "@ant-design/icons";
import http from "../../helpers/http";
import config from "../../config/config";
import _ from "lodash";
import helper from "../../helpers/helper";

const Create = ({
  isVisible,
  handleClose,
  moduleName,
  image_url,
  base_url,
}) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});

  const sizeLimit = config.sizeLimit;

  const allowedExtensions = config.allowed_extensions;
  const joiOptions = config.joiOptions;

  const validate = (value) => {
    let schemaObj = {
      image: Joi.required(),
      title: Joi.string().required(),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    helper.validateSize("image", value.image, sizeLimit, errors);
    helper.validateExt("image", value.image, allowedExtensions, errors);

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    console.log({value:value.products});
    let errors = validate(value);
    setFormError(errors);
    console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(_.omit(value, ["image","products"]))) {
      formData.append(key, data || "");
    }
    if (value.image) {
      formData.append("image", value.image.file);
    }
   
    if (value.products) {
      value.products.forEach((obj, index) => {
       
        formData.append(`products[${index}][name]`, obj.name);
        formData.append(`products[${index}][link]`, obj.link);
      });
    }
   

    

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      form.resetFields();

      handleClose();
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      open={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}

      width={800}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Title"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              required
              label="Image: (000 X 000)"
              name="image"
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Products:"> 
            <Form.List name="products"  >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing name",
                          },
                        ]}
                      >
                        <Input placeholder="Name" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "link"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing link",
                          },
                        ]}
                      >
                        <Input placeholder="Link" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Product
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            </Form.Item>
           
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
