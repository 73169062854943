import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Radio,
  InputNumber,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import Joi from "joi-browser";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import HtmlEditor from "../../components/HtmlEditor";

function Edit({ isVisible, handleClose, dbData, moduleName }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [isVideoVisible, setisVideoVisible] = useState(false);
  const [description, setDescription] = useState();

  const base_url = config.api_url + "admin/homepage/features";
  const image_url = config.api_url + "uploads/homepageFeature/";
  const sizeLimit = 1;

  const allowedExt = [
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/svg",
  ];

  // form.setFieldsValue(_.omit(dbData, ["image", "thumbnail"]));

const validateSize = (image,sizeLimit)=>{
  if (image.file.size > sizeLimit * 1024 * 1024) {
    return `File needs to be under ${sizeLimit}MB`;
  }
  return false
}
const validateExt = (image,allowedExt)=>{
  if (image.file.size > sizeLimit * 1024 * 1024) {
    return `File needs to be under ${sizeLimit}MB`;
  }
  return false
}




  const validate = (value) => {
    let schemaObj = {
      title: Joi.required(),
      
    };
    console.log(value);

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.bg_image) {
      if (value.bg_image.file.size > sizeLimit * 1024 * 1024) {
        errors["bg_image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(value.bg_image.file.type)) {
        errors["bg_image"] = "File does not have a valid file extension.";
      }
    }
    if (value.icon) {
      if (value.icon.file.size > sizeLimit * 1024 * 1024) {
        errors["icon"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(value.icon.file.type)) {
        errors["icon"] = "File does not have a valid file extension.";
      }
    }
    if (value.hover_icon) {
      if (value.hover_icon.file.size > sizeLimit * 1024 * 1024) {
        errors["hover_icon"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(value.hover_icon.file.type)) {
        errors["hover_icon"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(value, ["bg_image", "icon"])
    )) {
      formData.append(key, data || "");
    }
    if (value.bg_image) {
      formData.append("bg_image", value.bg_image.file);
    }
    if (value.icon) {
      formData.append("icon", value.icon.file);
    }
    if (value.hover_icon) {
      formData.append("hover_icon", value.hover_icon.file);
    }

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };

  const handleTypeChange = (type) => {
    if (type.target.value === "video") {
      setisVideoVisible(true);
    } else {
      setisVideoVisible(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["bg_image", "icon", "description","hover_icon"]));

    setDescription(() => {
      return dbData.description;
    });
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.description = description;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={700}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Title:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={24}>
            <Form.Item
              getValueProps={(i) => {}}
              
              label="Description:"
              name="description"
              {...(formError.description && {
                help: formError.description,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                id="edit-editor"
                initialValue={dbData.description}
                textareaName="description"
                height={350}
                onEditorChange={(newText) => setDescription(newText)}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Icon: (40 X 40)"
              name="icon"
              {...(formError.icon && {
                help: formError.icon,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.icon
                      ? image_url + dbData.icon
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Hover Icon: (40 X 40)"
              name="hover_icon"
              {...(formError.hover_icon && {
                help: formError.hover_icon,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.hover_icon
                      ? image_url + dbData.hover_icon
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Background Image: (398 X 362)"
              name="bg_image"
              {...(formError.bg_image && {
                help: formError.bg_image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.bg_image
                      ? image_url + dbData.bg_image
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
