import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  notification,
  Upload,
  Row,
  Col,
  Button,
  Input,
  Space,
  Select,
  Switch,
} from "antd";

import {
  PlusOutlined,
  MinusCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Joi from "joi";
import _ from "lodash";
import http from "../../helpers/http";
import config from "../../config/config";
import helper from "../../helpers/helper";
import slugify from "react-slugify";
import HtmlEditor from "../../components/HtmlEditor";
function Edit({
  isVisible,
  handleClose,
  dbData,
  moduleName,
  image_url,
  base_url,
  categories,
}) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [spec_one_desc, set_spec_one_desc] = useState();
  const [spec_two_desc, set_spec_two_desc] = useState();
  const [spec_three_desc, set_spec_three_desc] = useState();
  const [isParent, setIsParent] = useState(false);
  const sizeLimit = config.sizeLimit;

  const allowedExtensions = config.allowed_extensions;
  const joiOptions = config.joiOptions;
  const { Option } = Select;
  const { TextArea } = Input;
  const validate = (value) => {
    let schemaObj = {
      // image: Joi.required(),

      title: Joi.string().required(),
      slug: Joi.string().required(),
      category: Joi.string().required(),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    helper.validateSize("image", value.image, sizeLimit, errors);
    helper.validateExt("image", value.image, allowedExtensions, errors);
    helper.validateSize("thumbnail", value.thumbnail, sizeLimit, errors);
    helper.validateExt("thumbnail", value.thumbnail, allowedExtensions, errors);
    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(value, ["image", "standards", "thumbnail","have_child"])
    )) {
      formData.append(key, data || "");
    }

    formData.append("have_child", value.have_child);

    if (value.image) {
      formData.append("image", value.image.file);
    }
    if (value.thumbnail) {
      formData.append("thumbnail", value.thumbnail.file);
    }

    if (value.standards) {
      value.standards.forEach((obj, index) => {
        formData.append(`standards[${index}][name]`, obj.name);
      });
    }

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };

  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image", "thumbnail"]));

    set_spec_one_desc(dbData.spec_one_desc);
    set_spec_two_desc(dbData.spec_two_desc);
    set_spec_three_desc(dbData.spec_three_desc);
    setIsParent(dbData.have_child);
  }, [dbData]);

  const handleSwitch = (value) => {
    setIsParent(value);
  };

  const columnVisibility = {
    display: isParent ? "none" : "block",
  };

  return (
    <Modal
      title={`Edit ${moduleName}`}
      open={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.spec_one_desc = spec_one_desc;
            values.spec_two_desc = spec_two_desc;
            values.spec_three_desc = spec_three_desc;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1200}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Have Child Product?"
              name="have_child"
              {...(formError.have_child && {
                help: formError.have_child,
                validateStatus: "error",
              })}
            >
              <Switch defaultChecked={dbData.have_child} onChange={handleSwitch} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Category:"
              name="category"
              {...(formError.category && {
                help: formError.category,
                validateStatus: "error",
              })}
            >
              <Select
                placeholder="Select Category"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{`${data.title}`}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Title"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input
                onChange={(value) => {
                  form.setFieldsValue({ slug: slugify(value.target.value) });
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Slug"
              name="slug"
              {...(formError.slug && {
                help: formError.slug,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col xs={24} md={8} style={columnVisibility}>
            <Form.Item
              label="First Specification Title"
              name="spec_one_title"
              {...(formError.spec_one_title && {
                help: formError.spec_one_title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} style={columnVisibility}>
            <Form.Item
              label="Second Specification Title"
              name="spec_two_title"
              {...(formError.spec_two_title && {
                help: formError.spec_two_title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} style={columnVisibility}>
            <Form.Item
              label="Third Specification Title"
              name="spec_three_title"
              {...(formError.spec_three_title && {
                help: formError.spec_three_title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} style={columnVisibility}>
            <Form.Item
              getValueProps={(i) => {}}
              label="First Specification Description"
              name="spec_one_desc"
              {...(formError.spec_one_desc && {
                help: formError.spec_one_desc,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                initialValue={dbData.spec_one_desc}
                id="create-editor-1"
                textareaName="spec_one_desc"
                height={350}
                onEditorChange={(newText) => set_spec_one_desc(newText)}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={8} style={columnVisibility}>
            <Form.Item
              getValueProps={(i) => {}}
              label="Second Specification Description"
              name="spec_two_desc"
              {...(formError.spec_two_desc && {
                help: formError.spec_two_desc,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                initialValue={dbData.spec_two_desc}
                id="create-editor-2"
                textareaName="spec_two_desc"
                height={350}
                onEditorChange={(newText) => set_spec_two_desc(newText)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} style={columnVisibility}>
            <Form.Item
              getValueProps={(i) => {}}
              label="Third Specification Description"
              name="spec_three_desc"
              {...(formError.spec_three_desc && {
                help: formError.spec_three_desc,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                initialValue={dbData.spec_three_desc}
                id="create-editor-3"
                textareaName="spec_three_desc"
                height={350}
                onEditorChange={(newText) => set_spec_three_desc(newText)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={8} style={columnVisibility}>
            <Form.Item
              label="Application Areas"
              name="application_areas"
              {...(formError.application_areas && {
                help: formError.application_areas,
                validateStatus: "error",
              })}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              
              label="Thumbnail: (000 X 000)"
              name="thumbnail"
              {...(formError.thumbnail && {
                help: formError.thumbnail,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.thumbnail
                      ? image_url + dbData.thumbnail
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} md={8} style={columnVisibility}>
            <Form.Item
              
              label="Image: (000 X 000)"
              name="image"
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: dbData.image
                      ? image_url + dbData.image
                      : "https://via.placeholder.com/100",
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>

          <Col xs={24} md={24} style={columnVisibility}>
            <Form.Item label="Standards:">
              <Form.List name="standards">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing name",
                            },
                          ]}
                        >
                          <Input placeholder="Name" />
                        </Form.Item>

                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Standard
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}></Row>
      </Form>
    </Modal>
  );
}

export default Edit;
