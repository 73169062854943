import React, { useState } from "react";
import "./App.less";
import { Layout, Menu, Modal, Drawer, Button } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import {
  ExclamationCircleOutlined,
  MenuOutlined,
  HolderOutlined,
} from "@ant-design/icons";

import Sliders from "./pages/sliders/Index";
import Partners from "./pages/HomepagePartners/Index";
import Certificates from "./pages/HomepageCertificates/Index";
import Awards from "./pages/HomepageAwards/Index";
import Applications from "./pages/Applications/Index";
import Categories from "./pages/ProductCategories/Index";
import Products from "./pages/Products/Index";
import ProductsV2 from "./pages/ProductsV2/Index";
import ChildProducts from "./pages/ChildProducts/Index";
import ChildProductsV2 from "./pages/ChildProductsV2/Index";
import HomepageContent from "./pages/Content/Homepage";
import ContactLeads from "./pages/leads/Contact";
import FinancialCategory from "./pages/FinancialCategory/Index";

import FinancialDocument from "./pages/FinancialDocument/Index";







import HomepageFeatures from "./pages/HomepageFeatures/Index";
import HomepageGalleries from "./pages/HomepageGalleries/Index";
import HomepageBrownies from "./pages/HomepageBrownies/Index";
import HomepageLivingFeatures from "./pages/HomepageLivingFeatures/Index";
import AmenityCategories from "./pages/AmenityCategories/Index";
import AmenityGalleries from "./pages/AmenityGalleries/Index";
import Amenities from "./pages/Amenities/Index";
import Teams from "./pages/Teams/Index";
import HomepagePerks from "./pages/Content/HomepagePerks";
import AmenityContent from "./pages/Content/Amenity";
import NotFound from "./pages/NotFound/Index";

import Logout from "./components/Logout";

function MainLayout(props) {
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedKeys, setselectedKeys] = useState(0);
  const onClose = () => {
    setOpen(false);
  };
  const onCollapse = (collapsed) => {
    setCollapsed(!collapsed);
  };

  const showLogoutConfirm = (id) => {
    confirm({
      title: `Are you sure you want to logout?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        // return handleDelete(id);
        props.history.push("/logout");
      },
      onCancel() {},
    });
  };
  const menuitems = [
    // { label: "item 1", key: "item-1" }, // remember to pass the key prop
   
    {
      label: "Homepage",
      key: "homepage-submenu",
      icon: <HolderOutlined />,

      children: [
        {
          label: <Link to="/homepage/partners">Partners</Link>,
          key: "partners",
        },
        {
          label: <Link to="/homepage/certificates">Certificates</Link>,
          key: "certificates",
        },
        {
          label: <Link to="/homepage/awards">Awards</Link>,
          key: "awards",
        },
        {
          label: <Link to="/homepage/content">Content</Link>,
          key: "content",
        },
      ],
    },
    {
      label: "Products",
      key: "products-submenu",
      icon: <HolderOutlined />,

      children: [
        {
          label: <Link to="/products/categories">Categories</Link>,
          key: "categories",
        },
        {
          label: <Link to="/products-v2">Products</Link>,
          key: "products",
        },
        {
          label: <Link to="/products/childs-v2">Child Products</Link>,
          key: "product-childs",
        },
      ],
    },
    {
      label: "Leads",
      key: "leads-submenu",
      icon: <HolderOutlined />,

      children: [
        {
          label: <Link to="/leads/contact">Contact</Link>,
          key: "contact",
        },
      
      ],
    },
    {
      label: "Financial",
      key: "financial-submenu",
      icon: <HolderOutlined />,

      children: [
        {
          label: <Link to="/financial/categories">Categories</Link>,
          key: "financial-categories",
        },
        {
          label: <Link to="/financial/documents">Documents</Link>,
          key: "financial-documents",
        },
      
      ],
    },
    {
      icon: <HolderOutlined />,
      label: <Link to="/applications">Applications</Link>,
      key: "applications",
    }, 
  ];
  const topMenuItems = [
    {
      label: (
        <Link
          to=""
          onClick={() => {
            showLogoutConfirm();
          }}
        >
          Logout
        </Link>
      ),
      key: "Logout",
    }, // remember to pass the key prop
    // { label: "item 2", key: "item-2" }, // which is required
  ];
  const MenuComponent = (
    <Menu theme="light" mode="inline" items={menuitems}>
      {/* <SubMenu key="Homepage" icon={<HolderOutlined />} title="Homepage">
      <Menu.Item key="1">
          <Link to="/homepage/partners">Partners</Link>
        </Menu.Item>
        <Menu.Item key="10">
          <Link to="/homepage/galleries">Galleries</Link>
        </Menu.Item>
       
        <Menu.Item key="2">
          <Link to="/homepage/features">Features</Link>
        </Menu.Item>
        
        <Menu.Item key="3">
          <Link to="/homepage/brownies">Brownies</Link>
        </Menu.Item>
        <Menu.Item key="11">
          <Link to="/homepage/living-features">Living Features</Link>
        </Menu.Item>
       
        <Menu.Item key="4">
          <Link to="/homepage/content">Content</Link>
        </Menu.Item>
      </SubMenu> */}
      {/* <SubMenu key="AboutUs" icon={<HolderOutlined />} title="About Us">
        <Menu.Item key="5">
          <Link to="/about-us/teams">Teams</Link>
        </Menu.Item>
      </SubMenu> */}
      {/* <SubMenu key="Amenity" icon={<HolderOutlined />} title="Amenities">
        <Menu.Item key="6">
          <Link to="/amenity/categories">Category</Link>
          
        </Menu.Item>
        <Menu.Item key="7">
          <Link to="/amenities">Amenities</Link>
          
        </Menu.Item>
        <Menu.Item key="8">
          <Link to="/amenity/galleries">Gallery</Link>
          
        </Menu.Item>
        <Menu.Item key="9">
        <Link to="/amenity/content">Content</Link>
          
        </Menu.Item>
      </SubMenu> */}
    </Menu>
  );
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          // theme="dark"
          // collapsible
          // collapsed={collapsed}
          // onCollapse={() => {
          //   onCollapse(collapsed);
          // }}
          theme="light"
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          trigger={null}
        >
          {/* <div className="logo">
            <span>Raymond</span>
            <img src={Logo} className="img-fluid" />
          </div> */}
          {MenuComponent}
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Button
              className="menu"
              type="primary"
              icon={<MenuOutlined />}
              onClick={() => setOpen(true)}
            />
            <Menu theme="dark" mode="horizontal" items={topMenuItems} />
            {/* <Menu.Item key="1" >
                <Link
                to=""
                  onClick={() => {
                    showLogoutConfirm();
                  }}
                >
                  Logout
                </Link>
              </Menu.Item> */}
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Switch>
              <Route exact path="/" component={Partners} />

              <Route exact path="/homepage/partners" component={Partners} />
              <Route exact path="/homepage/certificates" component={Certificates} />
              <Route exact path="/homepage/awards" component={Awards} />
              <Route
                exact
                path="/homepage/content"
                component={HomepageContent}
              />
              <Route exact path="/applications" component={Applications} />
              <Route exact path="/products/categories" component={Categories} />
              <Route exact path="/products" component={Products} />
              <Route exact path="/products-v2" component={ProductsV2} />
              <Route exact path="/products/childs" component={ChildProducts} />
              <Route exact path="/products/childs-v2" component={ChildProductsV2} />
              <Route exact path="/leads/contact" component={ContactLeads} />


              <Route exact path="/financial/categories" component={FinancialCategory} />
              <Route exact path="/financial/documents" component={FinancialDocument} />



              <Route
                exact
                path="/homepage/features"
                component={HomepageFeatures}
              />
              <Route
                exact
                path="/homepage/galleries"
                component={HomepageGalleries}
              />
              <Route
                exact
                path="/homepage/brownies"
                component={HomepageBrownies}
              />
              <Route
                exact
                path="/homepage/living-features"
                component={HomepageLivingFeatures}
              />
             
              <Route exact path="/homepage/perks" component={HomepagePerks} />
              <Route
                exact
                path="/amenity/categories"
                component={AmenityCategories}
              />
              <Route
                exact
                path="/amenity/galleries"
                component={AmenityGalleries}
              />
              <Route exact path="/amenities" component={Amenities} />
              <Route exact path="/amenity/content" component={AmenityContent} />

              <Route exact path="/about-us/teams" component={Teams} />

              <Route exact path="/logout" component={Logout} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Design & Developed by Togglehead
          </Footer>
        </Layout>

        <Drawer title="Menu" placement="left" onClose={onClose} open={open}>
          {MenuComponent}
        </Drawer>
      </Layout>
    </Router>
  );
}

export default MainLayout;
