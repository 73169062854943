import React, { useState } from "react";
import { Modal, Form, notification, Upload, Row, Col ,Button,Input,Space,Select} from "antd";
import Joi from "joi";
import { PlusOutlined,MinusCircleOutlined,UploadOutlined } from "@ant-design/icons";
import http from "../../helpers/http";
import config from "../../config/config";
import _ from "lodash";
import helper from "../../helpers/helper";

const Create = ({
  isVisible,
  handleClose,
  moduleName,
  image_url,
  base_url,
  categories,

}) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});

  const sizeLimit = 50;

  const allowedExtensions = config.allowed_extensions;
  const joiOptions = config.joiOptions;



  const { TextArea } = Input;
  const { Option } = Select;


  const validate = (value) => {
    let schemaObj = {
      document: Joi.required(),
      title: Joi.string().required(),
      category: Joi.string().required(),

    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    helper.validateSize("document", value.document, sizeLimit, errors);
    helper.validateExt("document", value.document, allowedExtensions, errors);

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    console.log({value:value.products});
    let errors = validate(value);
    setFormError(errors);
    console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(_.omit(value,["document"]))) {
      formData.append(key, data || "");
    }
    if (value.document) {
      formData.append("document", value.document.file);
    }
   
    
   

    

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      form.resetFields();

      handleClose();
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      open={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values);
          })
          .catch((info) => {});
      }}

      // width={700}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
        <Col xs={24} md={24}>
            <Form.Item
              required
              label="Category:"
              name="category"
              {...(formError.category && {
                help: formError.category,
                validateStatus: "error",
              })}
            >
              <Select
                placeholder="Select Category"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{`${data.title}`}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              required
              label="Title"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Document:"
              name="document"
              {...(formError.document && {
                help: formError.document,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="text"
                showUploadList={{
                  showPreviewIcon: true,
                  showRemoveIcon: false,
                }}
              >
                <Button icon={<UploadOutlined />}>Select File</Button>
                {/* <PlusOutlined /> */}
              </Upload>
            </Form.Item>
          </Col>
         
          
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
