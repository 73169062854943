import React, { useState } from "react";
import {
  Modal,
  Form,
  notification,
  Upload,
  Row,
  Col,
  Button,
  Input,
  Space,
  Select,
  Switch,
} from "antd";
import Joi from "joi";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import http from "../../helpers/http";
import config from "../../config/config";
import _ from "lodash";
import helper from "../../helpers/helper";
import slugify from "react-slugify";
import HtmlEditor from "../../components/HtmlEditor";

const Create = ({
  isVisible,
  handleClose,
  moduleName,
  image_url,
  base_url,
  categories,
}) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [spec_one_desc, set_spec_one_desc] = useState();
  const [spec_two_desc, set_spec_two_desc] = useState();
  const [spec_three_desc, set_spec_three_desc] = useState();
  // const [isParent, setIsParent] = useState(false);

  const sizeLimit = config.sizeLimit;

  const allowedExtensions = config.allowed_extensions;
  const joiOptions = config.joiOptions;
  const { Option } = Select;
  const { TextArea } = Input;
  const validate = (value) => {
    let schemaObj = {
      // image: Joi.required(),

      title: Joi.string().required(),
      // slug: Joi.string().required(),
      parent: Joi.string().required(),
    };

    const schema = Joi.object(schemaObj).options({ allowUnknown: true });

    const { error } = schema.validate(value, joiOptions);

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

  

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    console.log({ value });
    let errors = validate(value);
    setFormError(errors);
    console.log(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();

    for (const [key, data] of Object.entries(
      _.omit(value, ["image", "standards"])
    )) {
      formData.append(key, data || "");
    }
    if (value.image) {
      formData.append("image", value.image.file);
    }
    // if (value.thumbnail) {
    //   formData.append("thumbnail", value.thumbnail.file);
    // }

    if (value.standards) {
      value.standards.forEach((obj, index) => {
        formData.append(`standards[${index}][name]`, obj.name);
      });
    }

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      form.resetFields();

      handleClose();
    }
  };

  // const handleSwitch = (value) => {
  //   setIsParent(value);
  // };

  // const columnVisibility = {
  //   display: isParent ? "none" : "block",
  // };

  return (
    <Modal
      title={`Add ${moduleName}`}
      open={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            
            values.spec_two_desc = spec_two_desc;
            
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1200}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          {/* <Col xs={24} md={24}>
            <Form.Item
              required
              label="Have Child Product?"
              name="have_child"
              {...(formError.have_child && {
                help: formError.have_child,
                validateStatus: "error",
              })}
            >
              <Switch onChange={handleSwitch} />
            </Form.Item>
          </Col> */}
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Parent Product:"
              name="parent"
              {...(formError.parent && {
                help: formError.parent,
                validateStatus: "error",
              })}
            >
              <Select
                placeholder="Select Parent"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{`${data.title}`}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              required
              label="Title"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input
                // onChange={(value) => {
                //   form.setFieldsValue({ slug: slugify(value.target.value) });
                // }}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} md={8}>
            <Form.Item
              required
              label="Slug"
              name="slug"
              {...(formError.slug && {
                help: formError.slug,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col> */}

         
          <Col xs={24} md={8} >
            <Form.Item
              label="Second Specification Title"
              name="spec_two_title"
              {...(formError.spec_two_title && {
                help: formError.spec_two_title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
        
        

          <Col xs={24} md={24} >
            <Form.Item
              getValueProps={(i) => {}}
              label="Second Specification Description"
              name="spec_two_desc"
              {...(formError.spec_two_desc && {
                help: formError.spec_two_desc,
                validateStatus: "error",
              })}
            >
              <HtmlEditor
                id="create-editor-2"
                textareaName="spec_two_desc"
                height={350}
                onEditorChange={(newText) => set_spec_two_desc(newText)}
              />
            </Form.Item>
          </Col>
        
         

         
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
